<template>
    <div class="box">
         <div class="logo">
           <img src="@/assets/logo.png"/>
         </div>

         <div class="form-item">
            <div class="name">手机号</div>
            <div class="val">
              <input type="number" name="mobile" v-model="form.mobile" maxlength="11" placeholder="请输入手机号"/>
            </div>     
         </div>
         <div class="form-item">
            <div class="name">密码</div>
            <div class="val">
              <input type="password" name="password" v-model="form.password" maxlength="18" placeholder="请输入密码"/>
            </div>
         </div>
         <label class="radio-agreement">
            <input type="checkbox" v-model="form.age" name="age" :value="true" />
            <div class="agreement">
              <span class="href">我已知悉，并年满18岁</span>
            </div>
         </label>
         <label class="radio-agreement">
            <input type="checkbox" v-model="form.agreement" :value="true" name="agreement" />
            <div class="agreement">
              <span>我已阅读并同意</span>
              <span class="href" @click.stop="onYhxy">《用户协议》</span>
              <span>及</span>
              <span class="href" @click.stop="onyszc">《隐私协议》</span>
            </div>
         </label> 
         <button class="button-submit" form-type="submit" :disabled="loading" :loading="loading" @click="onSubmit">登录</button>
         <!-- <div class="button-register" @click="onRegister">注册</div> -->
         <!-- <div class="reset-password" @click="onRestPassword">忘记密码了？</div> -->
    </div>
    </template>
    
    <script>
        import store from "@/store";
        import {Toast} from 'vant';
        export default {
            data() {
                return {
                     form:{
                       mobile: null,
                       password: null,
                       age: true,
                       agreement: true
                     },
                     loading: false,
                     redirect: "/"
                }
            },
            created() {
                this.redirect = this.$route.query.redirect || "/";
                console.log(this.redirect)
                let cache = localStorage.getItem("login");
                if(cache){
                    this.form = {
                        mobile: cache.mobile,
                        password: cache.password,
                        age: cache.age,
                        agreement: cache.agreement			
                    }
                }
            },
            methods: {
                onYhxy(){
                    this.$router.push({
                        path: '/agreement/yhxy'
                    })
                },
                onyszc(){
                    this.$router.push({
                        path: '/agreement/yszc'
                    })
                },
                onRegister(){
                    this.$router.push({
                        path: '/register/index',
                   })
                },
                onSubmit(){
                    let form = this.form;
                    try{
                        if(!form.mobile){
                            throw '请输入手机号';
                        }
                        if(form.mobile.length != 11){
                            throw '手机号格式不正确';
                        }
                        if(!form.password){
                            throw '请输入密码';
                        }
                        if(form.password.length < 6 || form.password.length > 18){
                            throw '请输入6-18位密码';
                        }
                        if(form.age != true){
                            throw '请勾选我已知悉，并年满18岁';
                        }
                        if(form.agreement != true){
                            throw '请阅读并同意《用户协议》《隐私协议》';
                        }
                    }catch(err){
                        Toast({ message: err, position:'cente'});
                        return false;
                    }
                    this.loading = true
                    Toast.loading({message: '登录中...', duration: 0});
                    this.request.post("/users/login", {
                        mobile: form.mobile,
                        password: form.password,
                        save30: true,
                    }).then(res=>{
                        if(res.code == 1){
                            Toast.success('登录成功');
                            //表单存储到本地
                            localStorage.setItem("login", {
                                 mobile: form.mobile,
                                 password: form.password,
                                 age: true,
                                 agreement: true
                            })
                            store.dispatch("user/setToken", res.data.token);
                            store.dispatch("user/setUserinfo", res.data.user);
                            this.$router.push({
                               path: this.redirect,
                            })
                        } else{
                            Toast({ message: res.msg, position:'cente'});
                        }
                        this.loading = false;
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false;
                        Toast({ message: '网络繁忙，请稍后重试', position:'cente'});
                    })
                }    		
            }
        }
    </script>
    
    <style>
.logo{
    text-align: center;
    padding: 15px;
}
.logo img{
    width: 100px;
    height: 100px;
}
.form-item{
  margin: 20px 30px;
}
.form-item .name{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(113, 113, 122, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 8px;
}
.form-item input{
  background: rgba(173, 173, 173, 0.1);
  height: 48px;
  line-height: 48px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(63, 63, 70, 1);
  text-align: left;
  vertical-align: top;
  padding: 0 16px;
}
.button-submit{
  height: 44px;
  background:  rgba(32, 197, 105, 1);
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0px;
  line-height: 44px !important;
  text-align: center;
  color: #ffffff;
  border-radius: 10px !important;
  margin: 30px 30px;
  padding: 0 !important;
}
.button-register{
  height: 44px;
  opacity: 1;
  border: rgba(32, 197, 105, 1) solid 1px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 44px;
  text-align: center;
  color:rgba(63, 63, 70, 1);
  border-radius: 10px;
  margin: 0px 30px;
}
.radio-agreement{
  display: flex;
  align-items: center;
  margin: 10px 30px;
}
.radio-agreement .agreement{
   font-size: 13px;
   color: #666666;
}
.radio-agreement .agreement .href{
   color: rgba(32, 197, 105, 1);
}
input[type="checkbox"]{
  width: 16px; 
  height: 16px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  margin: 5px;
}
input[type="checkbox"]:checked{
    color: #fff; /* 这里也可以设置对钩的颜色 */
    background-color: rgba(32, 197, 105, 1);
    border-color: rgba(32, 197, 105, 1);
}



    </style>
    